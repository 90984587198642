svg {
    max-width: none;
    height: auto;
    box-sizing: border-box
}

audio,
canvas,
iframe,
img,
svg,
video,
picture,
figure {
    vertical-align: middle
}

body::-webkit-scrollbar {
    display: none
}

body {
    -ms-overflow-style: none
}

html {
    scrollbar-width: none
}

.container {
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
    max-width: 100vw;
    max-width: calc((var(--title-size)*13) + (var(--container-padding)*2));
    margin: 0 auto
}

.container.full {
    padding-left: unset;
    padding-right: unset
}